<template>
  <div style="height: 100%">
    <nesi-user-common :title="`房源发布`">
      <template #content>
        <div class="container">
          <div class="step-wrapper" finish-status="success">
            <el-steps :active="1" align-center>
              <el-step title="查询权属信息"></el-step>
              <el-step title="填写房屋信息"></el-step>
              <el-step title="发布申请结果"></el-step>
            </el-steps>
          </div>
          <div class="form-wrapper">
            <div class="form">
              <el-form
                ref="formRef"
                :model="form"
                label-width="110px"
                label-position="right"
                :rules="formRules"
              >
                <el-form-item label="权属证类型：" prop="certType">
                  <el-select
                    v-model="form.certType"
                    placeholder="请选择权属证类型"
                    style="width: 100%"
                    @change="form.change"
                  >
                    <el-option label="房屋所有权证号" value="1"></el-option>
                    <el-option label="不动产权证号" value="4"></el-option>
                    <el-option label="预售合同备案编号" value="2"></el-option>
                    <el-option label="商品房网签合同编号" value="3"></el-option>
                    <el-option label="租赁备案证明号" value="6"></el-option>
                    <el-option label="其他证明" value="5"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="权属证号：" prop="certNo">
                  <div class="mr-5 tip-color" v-if="desc.isShowText1">
                    {{ desc.text1 }}
                  </div>
                  <el-input
                    v-if="desc.isShowText2"
                    v-model.trim="form.year"
                  ></el-input>
                  <div class="mlr-5 tip-color" v-if="desc.isShowText2">
                    {{ desc.text2 }}
                  </div>
                  <el-input v-model.trim="form.certNo"></el-input>
                  <div
                    class="ml-5 tip-color"
                    v-if="desc.isShowText1 || desc.isShowText2"
                  >
                    {{ desc.text3 }}
                  </div>
                </el-form-item>
                <el-form-item style="margin-top: 30px">
                  <el-button type="primary" @click="onSubmit">下一步</el-button>
                  <el-button @click="back">返回</el-button>
                </el-form-item>
              </el-form>
            </div>
            <div class="tip-wrapper">
              <div class="title">
                <i class="el-icon-warning-outline mr-2"></i>温馨提示：
              </div>
              <div class="content">
                <p>1. 所有权证样例：邕房权证字第000004号</p>
                <p>2. 不动产权证样例：桂(2017)南宁市不动产权第0011024号</p>
                <p>3. 合同备案编号样例：YS12345678</p>
                <p>4. 商品房网签合同编号样例：QF2019010100123</p>
                <p>5. 住房租赁网签备案编号样例：南宁租证字第202004842号</p>
              </div>
            </div>
          </div>
        </div>
      </template>
    </nesi-user-common>
  </div>
</template>

<script setup>
import NesiUserCommon from '@/components/NesiUserCommon/index.vue'
import { defineComponent, reactive, onMounted, watch, ref } from 'vue'
import { getOwnHouseInfo } from '@/api/user-house'
import { useRouter, useRoute } from 'vue-router'
import { ElMessageBox, ElMessage } from 'element-plus'
import { useStore } from 'vuex'
defineComponent({
  NesiUserCommon
})
const router = useRouter()
const route = useRoute()
const store = useStore()

const formRef = ref(null)
const type = ref('')

// 处理显示隐藏文案信息
const desc = reactive({
  text1: '邕房权证字第',
  text2: ')南宁市不动产权第',
  text3: '号',
  isShowText1: false,
  isShowText2: false,
  label1: '权利人名称：',
  label2: '权利人证件：',
  dealDesc: (label1, label2, text1, isShowText1, isShowText2) => {
    desc.text1 = text1
    desc.label1 = label1
    desc.label2 = label2
    desc.isShowText1 = isShowText1
    desc.isShowText2 = isShowText2
  }
})
// 处理表单信息
const form = reactive({
  year: '',
  certNo: '',
  certType: '',
  warrantNo: '', // 权证号
  rightHolderName: '', // 权利人姓名
  warrantType: '', // 权证类型
  rightHolderID: '', // 权利人证件号码
  type: '1', // 1房东 2 企业"
  change: (index) => {
    form.certNo = ''
    switch (index) {
      case '1':
        desc.dealDesc(
          '权利人名称：',
          '权利人证件：',
          '邕房权证字第',
          true,
          false
        )
        form.warrantType = '1'
        break
      case '4':
        desc.dealDesc('权利人名称：', '权利人证件：', '桂(', true, true)
        form.warrantType = '1'
        break
      case '6':
        desc.dealDesc(
          '使用权人名称：',
          '使用权人证件：',
          '南宁租证字第',
          true,
          false
        )
        form.warrantType = '3'
        break
      case '2':
      case '3':
        desc.dealDesc('权利人名称：', '权利人证件：', '', false, false)
        form.warrantType = '2'
        break
      case '5':
        desc.dealDesc('权利人名称：', '权利人证件：', '', false, false)
        form.warrantType = '4'
        break
      default:
        desc.dealDesc('权利人名称：', '权利人证件：', '', false, false)
        form.warrantType = ''
        form.warrantNo = ''
    }
  }
})

// const reset = () => {
//   form.year = ''
//   form.certNo = ''
//   form.certType = ''
//   form.warrantNo = ''
//   form.rightHolderName = ''
//   form.warrantType = ''
//   form.rightHolderID = ''
// }
const back = () => {
  router.push('/user/house/realese')
}

watch(
  () => form.certNo,
  () => {
    if (form.certType === '4') {
      form.warrantNo =
        desc.text1 + form.year + desc.text2 + form.certNo + desc.text3
    } else if (form.certType === '1' || form.certType === '6') {
      form.warrantNo = desc.text1 + form.certNo + desc.text3
    } else {
      form.warrantNo = form.certNo
    }
  }
)

const validator = (rule, value, callback) => {
  if (!value) callback(new Error('请输入权属证号'))
  if (value && form.certType === '4' && !form.year) {
    callback(new Error('请输入权属证号'))
  }
  callback()
}

const formRules = reactive({
  certType: [{ required: true, message: '请选择权属证类型', trigger: 'blur' }],
  certNo: [
    {
      required: true,
      validator: validator,
      message: '请输入权属证号',
      trigger: 'blur'
    }
  ]
})

const onSubmit = () => {
  formRef.value.validate(async (valid) => {
    if (!valid) return
    const payload = {
      warrantNo: form.warrantNo,
      warrantType: form.warrantType,
      type: form.type
    }
    const resp = await getOwnHouseInfo(payload)
    // 查询不到房源信息，走人工核验
    if (resp.code === 500) {
      if (
        resp.message ===
        '房屋所有权证号或不动产权证号和姓名和证件号码，未匹配到房屋信息，请仔细核对！'
      ) {
        ElMessageBox.confirm(resp.message, {
          confirmButtonText: '手动添加房源',
          cancelButtonText: '取消',
          title: '温馨提示',
          type: 'warning'
        }).then(async () => {
          store.commit('house/setWarrantData', payload)
          router.push('/user/house/handle')
        })
      } else {
        ElMessage.error(resp.message)
      }
    } else if (resp.code === 200) {
      ElMessage.success('操作成功')
      router.push('/user/house/realese')
    } else {
      ElMessage.error(resp)
    }
  })
}

onMounted(() => {
  if (route.query.type && route.query.type === '1') {
    type.value = route.query.type // 申请核验码标志
  }
})
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  height: 100%;
  .step-wrapper {
    padding: 30px 0;
  }

  .form-wrapper {
    margin: 20px auto;
    border: solid 1px #e4e7ed;
    padding: 24px;
    border-radius: 5px;
    transition: 0.2s;
    width: 95%;

    .form {
      width: 50%;
      height: 100%;
      margin-top: 30px;
    }
  }
}

::v-deep .el-form-item__content {
  display: flex;

  .el-input {
    flex: 1;
  }
}
.tip-color {
  color: #606266;
}

.mr-5 {
  margin-right: 5px;
}

.mr-2 {
  margin-right: 2px;
}

.ml-5 {
  margin-left: 5px;
}

.mlr-5 {
  margin-left: 5px;
  margin-right: 5px;
}

.tip-wrapper {
  margin: 20px;
  margin-top: 50px;
  color: #ff9900;
  background: #fdf6ec;
  padding: 10px;
  border-radius: 5px;

  .title {
    font-size: 14px;
  }
  .content {
    margin-top: 5px;
    // padding-left: 10px;
    font-size: 13px;

    p {
      height: 20px;
      line-height: 20px;
    }
  }
}
</style>
